


























































































































import Vue from 'vue';

export default Vue.extend({
  name: 'reset-password',

  data: () => ({
    showResetForm: true,
    email: '',
    newPassword: '',
    confirmPassword: '',
    showConfirmPassword: false,
    code: '',
    loading: false,
    formResetValid: false,
    formCodeValid: false,
    showPassword: false,
    validPassword: '',
    message: '',
    messageType: 'success',
  }),
  computed: {
    emailRules() {
      return [(v) => /.+@.+\..+/.test(v) || this.$t('dashboard.invalid-email')];
    },
    passwordRules() {
      return [
        (str) => !!str || this.$t('dashboard.invalid-password'),
        (str) => str.length >= 7 || this.$t('dashboard.invalid-password-lenght'),
        (str) => {
          const constructedRegEx =
            '^(?=(?:.*[0-9]){1})(?=(?:.*[a-z]){1})(?=(?:.*[A-Z]){1})(?=(?:.*[[!@#$%^&*()_+]){1}).+$';
          const PasswordRegEx = new RegExp(constructedRegEx, 'm');
          return PasswordRegEx.test(str) || this.$t('dashboard.invalid-password-format');
        },
      ];
    },
    codeRules() {
      return [(v) => !!v || this.$t('dashboard.invalid-password-code')];
    },
  },

  methods: {
    send() {
      if (this.showResetForm) {
        this.resetPassword();
      } else {
        this.confirmNewPassword();
      }
    },

    validaConfirmPassword(event: any) {
      if (event.target.value !== this.newPassword) {
        this.validPassword = this.$t('dashboard.invalid-password-match').toString();
      } else {
        this.validPassword = '';
      }
    },

    async resetPassword() {
      if (this.formResetValid) {
        this.loading = true;
        try {
          const resp = await this.$store.dispatch('auth/resetPassword', this.email);
          this.loading = false;
          this.showResetForm = false;

          this.messageType = 'success';
          this.message = this.$t('dashboard.reset-password-success').toString();
        } catch (error) {
          console.log('error in resetPassword');
          console.log(error);

          this.loading = false;
          this.messageType = 'error';
          this.message = this.$t('dashboard.reset-password-error').toString();
        }
      }
    },

    async confirmNewPassword() {
      if (this.formCodeValid) {
        this.loading = true;

        const data = { code: this.code, newPassword: this.newPassword };
        try {
          await this.$store.dispatch('auth/resetPasswordConfirm', data);
          this.loading = false;
          this.showResetForm = true;

          this.messageType = 'success';
          const message = this.$t('dashboard.reset-confirm-success').toString();
          this.$router.push({ name: 'Login', params: { message } });
        } catch (error) {
          console.log('error in confirmNewPassword');
          console.log(error);

          this.loading = false;
          this.messageType = 'error';
          this.message = this.$t('dashboard.reset-confirm-error').toString();
        }
      }
    },

    back() {
      this.$router.push('/');
    },
  },
});
